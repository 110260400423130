import { useTranslations } from '@vocab/react';
import {
  Column,
  Columns,
  Heading,
  Hidden,
  HiddenVisually,
  PageBlock,
  Stack,
} from 'braid-design-system';
import { memo } from 'react';

import { CPCarousel } from '../CPCarousel/CPCarousel.tsx';

import translations from './.vocab';
import { MoreRecommendations } from './DashboardRecommendations/MoreRecommendations.tsx';
import { TopRecommendations } from './DashboardRecommendations/TopRecommendations.tsx';
import SavedSearches from './DashboardSaveSearches/SavedSearches.tsx';
import SavedJobs from './DashboardSavedJobs/SavedJobs.tsx';

const NewSignedInDashboard = () => {
  const { t } = useTranslations(translations);

  return (
    <PageBlock component="section">
      <HiddenVisually>
        <h1>{t('Dashboard')}</h1>
      </HiddenVisually>

      <Columns space="xlarge">
        <Column>
          <Stack space="xlarge">
            <TopRecommendations />
            <Hidden above="tablet">
              <SavedSearches />
            </Hidden>
            <Hidden above="tablet">
              <SavedJobs />
            </Hidden>
            <CPCarousel
              sectionSpace="none"
              preserveSpace={false}
              homepageLayoutRefresh={true}
            />
            <MoreRecommendations />
          </Stack>
        </Column>
        <Column width="2/5" hideBelow="desktop">
          <Stack space="medium">
            <Heading level="3">{t('My Activity')}</Heading>
            <Stack space="large">
              <SavedSearches />
              <SavedJobs />
            </Stack>
          </Stack>
        </Column>
      </Columns>
    </PageBlock>
  );
};

export default memo(NewSignedInDashboard);
